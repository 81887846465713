

// MAIN BODY

body {
  // max-width: 1000px;
  // width: 90%;
  // margin: auto;

  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  min-height: 700px;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 0px 50px;
}

.body.container {
  padding-bottom: 200px;
}

div.narrow {
  padding: 0px 100px;
}

hr {
  border: none;
  border-top-style: solid;
  border-width: thin;
}





// NAVIGATION

nav {
  background-color: #000;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1;
  // opacity: 0.95;
}

nav div.container {
  max-width: none;
  margin: auto;
}

nav .logotype {
  // position: absolute;
  margin: auto 0;
  padding: 9.5px 0;

  font-size: 20px;
  font-weight: 700;
  color: white;
  letter-spacing: 0.2em;
}

nav div.float-right {
  float: right;
}

nav a {
  display: inline-block;
  position: relative;
  color: #DDD;
  padding: 12px 20px;
  text-decoration: none;
}

// Hovering
nav a:hover {
  color: #FFF;
}

// Current
nav a.current {
  color: #999;
}



nav .dropdown {
  display: inline-block;
  position: relative;
}

nav .dropdown-content {
  display: none;                                      // To Display Dropdowns
  position: absolute;
  padding: 10px 25px;
  min-width: 180px;

  background-color: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

nav .dropdown-content a {
  display: block;
  padding: 7px 0;

  color: #555;
  text-decoration: none;
  text-align: left;
}

nav .dropdown-content a * {
  margin: 0;
}


// Hovering
nav .dropdown:hover .dropdown-content {
  display: block;
  left: -5px;
  right: auto;
}

nav .dropdown-content a:hover {
  color: #000;
}

// Current
nav .dropdown .dropdown-content a.current {
  color: #999;
}

// Delimiters
nav .dropdown-content a.delimiter {
  border-top: 2px solid red;
  margin-top: 8px;
  padding-bottom: 2px;
  color: #F00;

  font-size: 0.8em;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-align: center;
}



// ============================================================================
// BODY
// ============================================================================


// BANNER HEADER

header {
  position: relative;
  background-image: linear-gradient(#000,#444);
  padding: 30px;
}

header.fullscreen {
  position: absolute;
  display: flex;
  padding: 0;
  height: 100vh;
  width: 100vw;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

header h1, header h2, header h3 {
  color: white;
  text-align: center;
}

header h1 {
  font-size: 4em;
  letter-spacing: -0.03em;
}

header h2 {
  font-size: 2em;
  letter-spacing: -0.01em;
}

header h3 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.15em;
  font-weight: 300;
}

header hr {
  border-color: #F00;
  color: #F00;
  width: 20vw;
}



// Headings / Paragraph Styles


h1, h2 {
  color: red;
}

h3, h4, h5, h6 {
  margin-bottom: -7px;
}


h1::before, h2::before, h3::before {
  display: block;
  content: " ";
  margin-top: -50px;
  height: 50px;
  visibility: hidden;
  pointer-events: none;
}

a { color: inherit; }

// p a:visited { color: #999; }

a:hover { color: red; }


.white-on-black {
  background-color: black;
  color: white;
}

h3 .delimiter {
  color: red;
  font-size: 0.8em;
  letter-spacing: 0.1em;
}

h3 a.delimiter {
  border-top:  1px solid red;
  border-top-style: solid;
}


table {
  justify-content: center;
}


p {
  line-height: 130%;
}

ol {
  // margin: 5px 0;
}

li {
  padding: 2px 0;
}

ol ol {
  list-style-type: lower-alpha;
}

.large {
  font-size: large;
}

.x-large {
  font-size: x-large
}

.center {
  text-align: center;
}

p img {
  display: block;
  margin: auto;
}


// CODE


code {
  font-size: 16px;
}


iframe {
  display: block;
  margin: 0 auto;
}

// EMBEDDED VIDEO

.max-width {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  overflow: auto;
}

.aspect-ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  // background: red;
}

.aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
